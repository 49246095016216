import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { RoutePaths } from '../route-paths';
const LandingPage = lazy(() => import('../../pages'));
const SchoolfeesPage = lazy(() => import('../../pages/user/schoolfees'));

export const homeRoutes: RouteObject[] = [
  {
    path: RoutePaths.HOMEPAGE,
    element: <LandingPage />,
  },
  {
    path: RoutePaths.SCHOOL_FEES,
    element: <SchoolfeesPage />,
  },
];
