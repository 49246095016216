import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // You can use 'auto' for instant scrolling
  });
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const isBase64Image = (value: string) => {
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  return base64regex.test(value);
};

export const convertBase64ImageToBlob = (value: string) => {
  const startingStringPosition = value.indexOf(';base64,');
  const type = value.substring(5, startingStringPosition);
  const base64String = value.substring(startingStringPosition + 8);

  const imageContent = atob(base64String);

  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  const blob = new Blob([buffer], { type });

  return blob as File;
};

export const convertToBase64String = (value: string) => {
  return `data:image/jpg;base64,${value}`;
};

export const convertURLToFile = async (value: string) => {
  const fileName = 'imageFile.png';
  const returnedImage = await fetch(value);
  const blob = await returnedImage.blob();

  const file = new File([blob], fileName, { type: 'image/png' });
  return file;
};

export function maskPhoneNumber(
  phoneNumber: number | string | undefined | null
): string {
  if (!phoneNumber) {
    return '';
  }

  const phoneStr = phoneNumber.toString();

  if (phoneStr.length <= 4) {
    return phoneStr;
  }

  const masked =
    phoneStr.slice(0, 3).replace(/\d/g, '*') +
    phoneStr.slice(3, -4).replace(/\d/g, '*') +
    phoneStr.slice(-4);

  return masked;
}

export const formatAmount = (amount: number): string => {
  const formattedAmount = amount.toFixed(2);
  return `₦${formattedAmount.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

export function calculate30DayIntervals(
  startDate: Date,
  numberOfIntervals: number
): Date[] {
  const result = [];
  let currentDate = new Date(startDate);

  for (let i = 0; i < numberOfIntervals; i++) {
    currentDate.setDate(currentDate.getDate() + 30);
    result.push(new Date(currentDate));
  }

  return result;
}

export function calculateMonthlyIntervals(
  startDate: Date,
  numberOfIntervals: number
): Date[] {
  const result: Date[] = [];

  for (let i = 1; i <= numberOfIntervals; i++) {
    const nextDate = new Date(startDate);
    nextDate.setMonth(startDate.getMonth() + i);
    result.push(nextDate);
  }

  return result;
}
